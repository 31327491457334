<template>
  <div class="language ">
    <div class="navbar-collapse" id="navbarNavDarkDropdown">
      <div class="dropdown">
        <a class=" dropdown-toggle header__menu--link"
           :class="{'dropdown-toggle': isShowLanguagePanel}" @click="openLanguagePanel"
           href="javascript: void (0)"
           style="font-weight:400;" role="button" id="dropdownMenuLink" data-toggle="dropdown"
           aria-expanded="false">
          <i class="fi " :class="['fi-' + language]"></i>
          <!--        <i class="fa fa-globe"></i> Language-->
        </a>
        <div class="dropdown-menu" style="" aria-labelledby="dropdownMenuLink" ref="dropdownMenuLink"
             v-show="isShowLanguagePanel">
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|en|gb');"><i class="fi fi-gb"></i> English</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|de|de');"><i class="fi fi-de"></i> Deutsch</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|fr|fr');"><i class="fi fi-fr"></i> Français</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|ja|jp');"><i class="fi fi-jp"></i> 日本語</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|ko|kr');"><i class="fi fi-kr"></i> 한국어</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|pt|pt');"><i class="fi fi-pt"></i> Português</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|ru|ru');"><i class="fi fi-ru"></i> Русский</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|sp|es');"><i class="fi fi-es"></i> Español</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|tr|tr');"><i class="fi fi-tr"></i> Türk</a>
          <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
             @click="doGTranslate('en|vn|vn');"><i class="fi fi-vn"></i> Tiếng Việt</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Language",
  data() {
    return {
      isShowLanguagePanel: false,
      language: 'gb',
      languageList: [
        {
          icon: 'gb',
          lang_pair: "en|en",
          title: 'English'
        },
        {
          icon: 'de',
          lang_pair: "en|de",
          title: 'Deutsch'
        },
        {
          icon: 'fr',
          lang_pair: "en|fr",
          title: 'Français'
        }
      ]
    }
  },
  created() {
    this.detectBrowserLanguage()
  },
  methods: {
    detectBrowserLanguage(){
      const browserLanguage = navigator.language || navigator.userLanguage;
      // console.log('lan:'+ browserLanguage)
      if (browserLanguage.startsWith('ja')) {
        this.language = 'jp';
      }else if (browserLanguage.startsWith('tr')) {
          this.language = 'tr';
          this.doGTranslate('en|tr|tr')
      }
      // else if (browserLanguage.startsWith('de')) {
      //   this.language = 'de';
      // } else if (browserLanguage.startsWith('fr')) {
      //   this.language = 'fr';
      // }
    },
    openLanguagePanel() {
      // this.$refs.dropdownMenuLink.style.display = 'block'
      console.log(111)
      this.isShowLanguagePanel = !this.isShowLanguagePanel;
    },
    GTranslateGetCurrentLang() {
      var keyValue = document['cookie']
          .match('(^|;) ?googtrans=([^;]*)(;|$)');
      return keyValue ? keyValue[2].split('/')[2] : null;
    },
    GTranslateFireEvent({element, event}) {
      try {
        if (document.createEventObject) {
          let evt = document.createEventObject();
          element.fireEvent('on' + event, evt)
        } else {
          let evt = document.createEvent('HTMLEvents');
          evt.initEvent(event, true, true);
          element.dispatchEvent(evt)
        }
        // $(".skiptranslate").css("display", "none");

        // const skiptranslate = document.querySelector('.skiptranslate');
        // skiptranslate.style.display = 'none';
        // document.getElementsByClassName('skiptranslate')[0].style.display = "none"
        const skiptranslateElement = document.querySelector('.skiptranslate');
        if (skiptranslateElement) {
          skiptranslateElement.style.display = 'none';
        }

        let body = document.querySelector("body");
        body.removeAttribute('style')
        // $("body").removeAttr("style");
        document.body.style = ""

      } catch (e) {
        console.log(e)
      }
    },
    doGTranslate(lang_pair) {
      const vm = this;
      try {
        if (lang_pair.value)
          lang_pair = lang_pair.value;
        if (lang_pair == '')
          return;
        var lang = lang_pair.split('|')[1];
        this.language = lang_pair.split('|')[2]
        if (vm.GTranslateGetCurrentLang() == null
            && lang == lang_pair.split('|')[0])
          return;
        var teCombo;
        var sel = document.getElementsByTagName('select');
        for (var i = 0; i < sel.length; i++)
          if (sel[i].className.indexOf('goog-te-combo') != -1) {
            teCombo = sel[i];
            break;
          }
        if (document.getElementById('google_translate_element2') == null
            || document.getElementById('google_translate_element2').innerHTML.length == 0
            || teCombo.length == 0 || teCombo.innerHTML.length == 0) {
          setTimeout(function () {
            vm.doGTranslate(lang_pair)
          }, 500)
        } else {
          teCombo.value = lang;
          vm.GTranslateFireEvent({element: teCombo, event: 'change'})
        }
        this.isShowLanguagePanel = false
      } catch (e) {

        console.log(e)
      }
    },
    TopOffset(el) {
      let rect = el.getBoundingClientRect(), scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return {top: rect.top + scrollTop};
    },
  }
}
</script>

<style scoped lang="scss">
.language {
  margin-left: 25px;
  .header__menu--link{

  }
  .dropdown-menu {
    display: block;
    margin-top: 5px;
  }
}
</style>
